@import 'bootstrap/scss/bootstrap.scss';
@import './fonts.scss';
@import 'bootstrap-overrides/mynavbar';

body {
  margin: 0;
  font-family: 'RobotoFont', sans-serif;

  .App {
    text-align: center;
  }
}
