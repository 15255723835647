@import '../../../styles/variables';
@import '../../../styles/mixins';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6em 0;
  box-shadow: 0 0.1em 0.2em 0 rgba(0, 0, 0, 0.2),
    0 0.2em 0.6em 0 rgba(0, 0, 0, 0.19);

  .logoTitleContainer {
    display: flex;
    align-items: flex-end;
    pointer-events: none;
  }

  .logo {
    width: 100%;
  }

  .div {
    font-size: 0.9em;

    .title {
      display: block;
      color: $primaryColor;
      font-weight: bold;
    }

    .tagline {
      border: solid #e25303;
      border-radius: 0.2em;
      padding: 0.1em 0.3em;
      background: $primaryColor;
      color: $whiteColor;
      font-weight: bold;
    }
  }
}
