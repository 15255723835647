@font-face {
  font-family: 'RobotoFont';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./../assets/fonts/Roboto/Regular/RobotoRegular.woff2')
    format('woff2');
}

@font-face {
  font-family: 'RobotoFont';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('./../assets/fonts/Roboto/Bold/RobotoBold.woff2') format('woff2');
}
