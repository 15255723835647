@import '../../../styles/mixins';

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  color: #212529;
  box-shadow: 0 -0.3em 0.3em -0.1em rgba(0, 0, 0, 0.1);

  p {
    @include p-styles;
    margin: 0;
    font-size: 0.8em;
  }
}
