@import 'variables';

@mixin a-styles {
  text-decoration: none;
  color: $lightTextColor;
  &:hover {
    color: $darkTextColor;
  }
  &[aria-current^='page'] {
    color: $darkTextColor;
    font-weight: bold;
  }
}

@mixin p-styles {
  color: $darkTextColor;
}
